// 1.仓库内容
const moudels= {
  namespaced: true,
  state: { // 存储
    // 如果是本地存储，本地存储需要同步到vuex，不然会导致登录界面消失
    token: window.localStorage.getItem('token')
  },
  // 操作state
  // mutations执行同步代码
  mutations: {
    setToken (state, token) {
      state.token = token
      // 设置本地存储 减少组件内部复杂度
      window.localStorage.setItem('token', token)
    }
  }
}
// 2.利用内容生成仓库实例
// const storeValue=new Vuex.Store(store)
export default moudels
