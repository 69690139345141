<template>
  <div class="grtyr">
    <el-card
      class="header"
      shadow="never"
      style="border: none; box-shadow: 0 0 5px 1px #999; border-radius: none"
    >
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
        <!-- v-for="(item, index) in $route.matched" -->
        <!-- v-for="(item, index) in toprouter" -->
        <el-breadcrumb-item
          v-for="(item, index) in $route.matched"
          :key="index"
          >{{ item.name }}</el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item>{{ $route.matched[1].name }}</el-breadcrumb-item> -->
      </el-breadcrumb>

      <el-dropdown trigger="click" @command="clickset" :hide-on-click="false">
        <span class="el-dropdown-link">
          <div class="user">
            <div class="user-img">
              <el-avatar icon="el-icon-user-solid" size="small"></el-avatar>
            </div>
            <div class="user-info">
              <span class="user-info-name">{{ username }}</span>
              <span class="user-info-coney">{{ companyname }}</span>
            </div>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-popover placement="left" width="390" trigger="click">
            <el-table :data="gridData">
              <el-table-column
                width="250"
                property="companyName"
                label="公司名称"
              ></el-table-column>
              <el-table-column width="60" property="companyName" label="操作">
                <template slot-scope="scope"
                  ><el-button type="text" @click="checkorder(scope.row, 1)"
                    >选择</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-dropdown-item command="2" slot="reference">
              <i class="el-icon-office-building"></i>切换公司</el-dropdown-item
            >
          </el-popover>
          <el-dropdown-item command="3">
            <i class="el-icon-full-screen"></i>
            全屏</el-dropdown-item
          >
          <el-dropdown-item command="4">
            <i class="el-icon-aim"></i>
            退出全屏</el-dropdown-item
          >
          <el-dropdown-item command="5">
            <i class="el-icon-switch-button"></i>退出登录</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-card>
  </div>
</template>

<script>
import { logout } from "@/api/user";
import { setToken } from "@/utils/setToken.js";
export default {
  data() {
    return {
      toprouter: [],
      username: "用户名",
      companyname: "华久包装德清产业园",
      gridData: [
        {
          date: "",
          name: "",
          address: "",
        },
        
      ],
    };
  },
  mounted() {
    (this.username = window.localStorage.getItem("userinfo")),
      (this.companyname = window.localStorage.getItem("companyName"));
  },
  created() {
    // window.localStorage.setItem("toprouter",JSON.stringify(route));
  },
  methods: {
    clickset(w) {
      if (w == 1) {
      } else if (w == 2) {
        this.gridData = JSON.parse(window.localStorage.getItem("loginUsers"));
      } else if (w == 3) {
        //全屏
        this.requestFullScreen(document.body);
      } else if (w == 4) {
        //退出全屏
        this.exitFullScreen();
      } else if (w == 5) {
        this.$confirm("确定退出登录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            logout().then((res) => {
              if (res.data.code == 200) {
                this.$message.success(res.data.message);
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("perjuri");
                window.localStorage.removeItem("soconds");
                this.$router.replace("/login");
                const wsuri = "ws://123.60.73.210:19999/websocket";
                this.websock = new WebSocket(wsuri);
                this.websock.close();
              } else {
                this.$message.error(res.data.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    checkorder(w, e) {
      console.log(w, e);
      if (w.permissions.length == 0||w.permissions==[]) {
        this.$message({
          type: "info",
          message: "该公司权限为空",
        });
      } else {
        window.sessionStorage.setItem("token", w.token);
        setToken("token", w.token);
        window.localStorage.setItem("userinfo", w.realname);
        window.localStorage.setItem("permissions", w.permissions);
        window.localStorage.setItem("companyName", w.companyName);
        window.localStorage.setItem("useronfo", JSON.stringify(w));
        window.localStorage.setItem("perjuri", JSON.stringify(w.permissions));
        this.$router.push({
          path: w.permissions[0].url,
        });

        this.$message({
          type: "success",
          message: "成功!",
        });
        //更新数据 刷新页面
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },

    requestFullScreen(element) {
      // 判断各种浏览器，找到正确的方法
      const requestMethod =
        element.requestFullScreen || // W3C
        element.webkitRequestFullScreen || // Chrome, safari
        element.mozRequestFullScreen || // FireFox
        element.msRequestFullscreen; // IE11
      if (requestMethod) {
        requestMethod.call(element);
      }
    },
    exitFullScreen() {
      var exitMethod =
        document.exitFullscreen || // W3C
        document.mozCancelFullScreen || // FireFox
        document.webkitExitFullscreen || // Chrome等
        document.msExitFullscreen; // IE11
      if (exitMethod) {
        exitMethod.call(document);
      }
    },
    websocketclose(e) {
      //关闭
      //重连
      // this.reconnect();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body,
.el-main {
  // margin-left: 12px;
  // padding: 15px 0 !important;
  display: flex;
  align-items: center;
  // height: calc(100vh - 24px); //61px为顶部header盒子高度，;
  justify-content: space-between;
  overflow-y: auto;
}
.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
  .user-img {
    width: 32px;
    height: 32px;
  }
  .user-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    .user-info-name {
      font-size: 14px;
      color: #333333;
    }
    .user-info-coney {
      margin-top: 4px;
      font-size: 10px;
      color: #7a7c80;
    }
  }
}
::v-deep .el-card {
  border-radius: none;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
</style>
