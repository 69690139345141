import axios from "axios";
import { getToken } from "./utils/setToken";
import router from "@/router/index";
import { Message, MessageBox } from "element-ui";
import { getusertoken } from "@/api/user";
import indexConfig from "@/config/index";
const service = axios.create({
  baseURL: indexConfig.baseURL,
  timeout: 20000, //请求超过这个时间就会中断
  Authorization: getToken("token"),
  // Access-Control-Allow-Origin： "*"
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // 请求前的操作(获取并设置token)
    // config.headers['Authorization'] = getToken('token')

    // const token = store.state.user.token
    // if (config.method === "post") {
    //   config.headers = { "Content-Type": "text/plain" };
    // }
    if (getToken("token") && config.url.indexOf("login") != 0)
      config.headers["authorization"] = getToken("token");

    config.headers["Token"] = getToken("token");
   
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
var showMsg = true;
// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么
    let { status, message } = response.data;
    if (response.data.code === 401) {
      MessageBox.confirm(
        "你的登录凭证已过期，可以取消继续留在该页面，或者重新登录",
        "提示",
        {
          closeOnClickModal: false,
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          showMsg = false;
          setTimeout(() => {
            showMsg = true;
          }, 3000);
          router.replace({ path: "/" });
          return false;
        })
        .catch(() => {});
      return false;
    } else if (response.data.code !== 200) {
      if (response.data.message == "token解析失败") {
        let msg = "你的登录凭证已过期，可以取消继续留在该页面，或者重新登录";
        MessageBox.confirm(msg, "提示", {
          closeOnClickModal: false,
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showMsg = false;
            setTimeout(() => {
              showMsg = true;
            }, 3000);
            router.replace({ path: "/" });
            return false;
          })
          .catch(() => {});
        return false;
      } else if (response.data.message == "token过期") {
        MessageBox.confirm(
          "你的登录凭证已过期，可以取消继续留在该页面，或者重新登录",
          "提示",
          {
            closeOnClickModal: false,
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            router.replace({ path: "/" });
            return false;
          })
          .catch(() => {});
        return false;
      } else if (response.data.message == "未登录或token过期，请登录！") {
        MessageBox.confirm(
          "你的登录凭证已过期，可以取消继续留在该页面，或者重新登录",
          "提示",
          {
            closeOnClickModal: false,
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            showMsg = false;
            setTimeout(() => {
              showMsg = true;
            }, 3000);
            router.replace({ path: "/" });
            return false;
          })
          .catch(() => {});
        return false;
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
