import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 修改 el-dialog 默认点击遮照不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
import service from "./service";
import echarts from "echarts";
import store from "./store/index";
//颜色选择器
// import vcolorpicker from 'vcolorpicker'
// Vue.use(vcolorpicker)
//引入路由
import router from "@/router";
//预览
import Viewer from "v-viewer";
//弹窗
import Message from "@/assets/js/messages.js";
Vue.use(Message);
// import { Decimal } from 'decimal.js'
// Vue.use(Decimal);
import "./utils/el-trim.js";
import "./utils/el-components.js";
import "./utils/dialogdrag.js";

import { throttle } from "./utils/golabel.js";
// 1. 全局挂载打印
import Print from "vue-print-nb";

Vue.use(Print);
//注释掉了
import "viewerjs/dist/viewer.css";
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  },
});
Viewer.setDefaults({
  // 需要配置的属性 注意属性并没有引号
  title: false,
  toolbar: false,
  navbar: false,
});
// import 'amfe-flexible/index.js'
Vue.prototype.throttle = throttle; //挂载到原型上
Vue.prototype.service = service; //挂载到原型上
Vue.prototype.$echarts = echarts; //挂载到原型上
Vue.config.productionTip = false;
Vue.use(ElementUI);
import { userPermissionList } from "@/api/user";
import $bus from "./mock/bus.js";
Vue.prototype.$bus = $bus;

// 路由导航守卫
router.beforeEach((to, from, next) => {
  // console.log(to, from, next,'to, from, next')
  if (from.path == "/login") {
    // userPermissionList().then((res) => {
    //   if (res.data.code == 200) {
    //     console.log("", res.data);
    //     localStorage.setItem("perjuri", JSON.stringify(res.data.result));
    //     console.log("", res.data.result);
    //     this.$store.commit("updateData", res.data.result);
    //     _this.$router.push({
    //       path: res.data.result[0].permissionList[0].url,
    //     });
    //     // //更新数据 刷新页面
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 1000);
    //   } else {
    //     _this.$message.error(res.data.message);
    //   }
    // });
  }

  let toPath = to.path;
  if (to.path.indexOf("login") != -1 || to.path.indexOf("login") != -1) {
  }
  if (
    !localStorage.getItem("token") &&
    localStorage.getItem("token") != null &&
    to.path.indexOf("login") != -1
  ) {
    if (to.path !== "/login") {
      router.push("/login");
      next();
    } else if (to.path === "/home") {
      // //权限

      router.push("/home");
      next();
    } else {
      

      next();
    }
  } else {
    next();

    // const { href } = router.resolve(to.path);
    // window.open(href, "_blank");
    // router.push("/login");
  }
});

//全局解析守卫
// router.beforeResolve(async to => {
//   // console.log('全局解析守卫',to)
//   //   const { href } = router.resolve(to.path);
//   //   window.open(href, "_blank");
//   //   router.push("/login");
//   // if (to.meta.requiresCamera) {
//   //   try {
//   //     await askForCameraPermission()
//   //   } catch (error) {
//   //     if (error instanceof NotAllowedError) {
//   //       // ... 处理错误，然后取消导航
//   //       return false
//   //     } else {
//   //       // 意料之外的错误，取消导航并把错误传给全局处理器
//   //       throw error
//   //     }
//   //   }
//   // }
// })
new Vue({
  store,
  render: (h) => h(App),
  //注册路由:写法是KV一致省略V，而且router是小写的
  //注册路由信息：当这里书写router的时候，组件身上都拥有$route $router属性
  router,
}).$mount("#app");
