import Vue from 'vue'
import ElementUI from 'element-ui';
 
/**
 * @MethodAuthor: YuanZheLi
 * @Date: 2022-01-10 15:27:00
 * @Description: 统一格式化去除input首尾空格
*/
Vue.component('el-input', {
  extends: ElementUI.Input,
  created() {
    this.$on('change', (value) => {
      this.$emit('input', value.trim())
     
      
    })
    
  }
})