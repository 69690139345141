import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=34f2b7f8&scoped=true"
import script from "./Menu.vue?vue&type=script&lang=js"
export * from "./Menu.vue?vue&type=script&lang=js"
import style0 from "./Menu.vue?vue&type=style&index=0&id=34f2b7f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.6_css-loader@6.11.0_webpack@5.94.0__lodash@4.17.21_v_nlqk7vo2zc6ayaxamjxrjj553y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f2b7f8",
  null
  
)

export default component.exports