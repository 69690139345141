<template>
  <div class="home">
    <!-- <Header /> -->
    <el-container class="content">
      <Menu />
      <el-container>
        <el-main>
          <Bread />
          <div class="cont">
            <router-view></router-view>
          </div>
        </el-main>
        <!-- <el-footer>
          <Footer />
        </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "./common/Header.vue";
// import Footer from './common/Footer.vue'
import Menu from "./common/Menu.vue";
import Bread from "./common/Breadcrumb.vue";
export default {
  components: {
    Header,
    // Footer,
    Menu,
    Bread,
  },
  created() {
    if (window.localStorage.getItem("refreshid") == 1) {
      window.location.reload();

      window.localStorage.setItem("refreshid", 0);
    }
    // 最后一步，把以下代码粘贴到需要刷新路由的地方
    // this.$set(this.routerParams, "query", { id: "150332000416" });
    // setTimeout(() => {
    //   let e = document.createEvent("MouseEvents");
    //   e.initEvent("click", true, true);
    //   document.getElementById("routerLink").dispatchEvent(e);
    // }, 50);
  },
  data() {
    return {};
  },
};
</script>

<!-- <style lang="scss">
.home {
  width: 100%;
  height: 100%;
  
  .content {
    // position: absolute;
    // width: 100%;
    // top: 60px;
    // bottom: 0;
    .cont{
      // height: 100%;
      margin: 20px;
      background: #f4f4f4;
    }
  }
}
.el-main {
  background: #f4f4f4;
  padding:  0;
}
::v-deep .el-card {
  border-radius: none;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
</style> -->
<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  position: relative;
  .content {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0;
    .cont {
      // height: 100%;
      margin: 20px;
      background: #f4f4f4;
    }
  }
}
// .el-aside {
//   display: block;
//   position: absolute;
//   left: 0;
//   top: 0px;
//   bottom: 0;
// }
.el-main {
  background: #f4f4f4;
  padding: 0;
}
::v-deep .el-card {
  border-radius: none;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
</style>
