// 模块化封装
// 用户操作的所有api接口
import instance from "../service";

/**
 * 发送短信验证码
 * @param {String} mobile - 手机号
 * @returns Promise
 */

// 传入对象，后对对象进行解构
// export const getSIMcode({url}){
//   return instance({
//     url      //instance返回pormise
//   })
// }


/*
 * 登录账户
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const userLogin = (form) => {
  return instance({
    url: 'login',
    data: form,
    method: 'POST'
  })
}


//获取token
export const getusertoken = (form) => {
  return instance({
    url: 'token',
    data: form,
    method: 'POST'
  })
}
export const logout = (form) => {
  return instance({
    url: 'logout',
    data: form,
    method: 'POST'
  })
}
/*
 * 增加用户权限
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const addUserJurisdiction = (addJuri) => {
  return instance({
    url: 'sys/permission/add',
    data: addJuri,
    method: 'POST'
  })
}


/*
 * 删除用户权限
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const deleteUserJurisdiction = (delJuri) => {
  return instance({
    url: 'sys/permission/delete',
    data: delJuri,
    method: 'POST'
  })
}

/*
 * 用户权限集合显示
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const showUserJurisdiction = (showJu) => {
  return instance({
    url: 'sys/permission/list',
    data: showJu,
    method: 'POST'
  })
}

/*
 * 用户权限更新
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const updataUserJurisdiction = (form) => {
  return instance({
    url: 'sys/permission/update',
    data: form,
    method: 'POST'
  })
}

/*
 * 用户路由集合显示
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const userPermissionList = (form) => {
  return instance({
    url: 'sys/permission/userPermissionList',
    data: form,
    method: 'POST'
  })
}
/*
 * 用户路由权限集合显示
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const userRolePermissionList = (form) => {
  return instance({
    url: 'sys/permission/userRolePermissionList',
    data: form,
    method: 'POST'
  })
}

/*
 * 人员管理集合显示
 * @param {String} mobile - 手机号
 * @param {String} code - 验证码
 * @return Promise
 *
*/
export const perpo= (form) => {
  return instance({
    url: 'sys/user/list',
    data: form,
    method: 'POST'
  })
}

//添加人员
export const userProcess= (form) => {
  return instance({
    url: 'sys/userProcess/add',
    data: form,
    method: 'POST'
  })
}

//冻结/删除人员
export const deleteProcess= (form) => {
  return instance({
    url: 'sys/user/delete',
    data: form,
    method: 'POST'
  })
}

//编辑人员
export const updataProcess= (form) => {
  return instance({
    url: 'sys/user/update',
    data: form,
    method: 'POST'
  })
}
//编辑人员
export const userupdateUserList= (form) => {
  return instance({
    url: 'sys/user/updateUserList',
    data: form,
    method: 'POST'
  })
}
//编辑人员
export const usersearchList= (form) => {
  return instance({
    url: 'sys/user/searchList',
    data: form,
    method: 'POST'
  })
}
//用户关联
export const userTypeRoleadd= (form) => {
  return instance({
    url: 'hj/userTypeRole/add',
    data: form,
    method: 'POST'
  })
}
export const userTypeRoledelete= (form) => {
  return instance({
    url: 'hj/userTypeRole/delete',
    data: form,
    method: 'POST'
  })
}
export const userTypeRolelist= (form) => {
  return instance({
    url: 'hj/userTypeRole/list',
    data: form,
    method: 'POST'
  })
}
export const userTypeRoletypeList= (form) => {
  return instance({
    url: 'hj/userTypeRole/typeList',
    data: form,
    method: 'POST'
  })
}
export const userTypeRoleupdate= (form) => {
  return instance({
    url: 'hj/userTypeRole/update',
    data: form,
    method: 'POST'
  })
}
//预警信息
export const messagelist= (form) => {
  return instance({
    url: 'op/message/list',
    data: form,
    method: 'POST'
  })
}

// 权限--推送人员】模块

export const userTypelist= (form) => {
  return instance({
    url: 'hj/userType/list',
    data: form,
    method: 'POST'
  })
}
export const userTypeupdate= (form) => {
  return instance({
    url: 'hj/userType/update',
    data: form,
    method: 'POST'
  })
}

export const userFilesadd= (form) => {
  return instance({
    url: 'sys/userFiles/add',
    data: form,
    method: 'POST'
  })
}
export const userFilesdelete= (form) => {
  return instance({
    url: 'sys/userFiles/delete',
    data: form,
    method: 'POST'
  })
}
export const userFileslist= (form) => {
  return instance({
    url: 'sys/userFiles/list',
    data: form,
    method: 'POST'
  })
}

export const userFilespage= (form) => {
  return instance({
    url: 'sys/userFiles/page',
    data: form,
    method: 'POST'
  })
}
export const userFilesupdate= (form) => {
  return instance({
    url: 'sys/userFiles/update',
    data: form,
    method: 'POST'
  })
}


