// let routes = window.localStorage.getItem("perjuri")
import file from "./../router/_import_production";
import store from "@/store/index";
import { fnAddDynamicMenuRoutes } from "@/assets/js/getAsyncRouter";
let att = [];
let constantRoutes = [
  {
    path: "/",
    redirect: "/login",
    hidden: true,
    component: () => import("@/components/Login"),
  },
  {
    path: "/login",
    name: "Login",
    hidden: true,
    component: () => import("@/components/Login"),
  },
  // {
  //   path: "/null",
  //   name: "Null",
  //   hidden: true,
  //   component: () => import("@/components/Null"),
  // },
  {
    path: "*",
    name: "NotFound",
    hidden: true,
    component: () => import("@/components/404.vue"),
  },
];
let routes = [
  {
    path: "/",
    redirect: "/login",
    hidden: true,
    component: () => import("@/components/Login"),
  },
  {
    path: "/login",
    name: "Login",
    hidden: true,
    component: () => import("@/components/Login"),
  },
  // {
  //   path: "/null",
  //   name: "Null",
  //   hidden: true,
  //   component: () => import("@/components/Null"),
  // },
  {
    path: "*",
    name: "NotFound",
    hidden: true,
    component: () => import("@/components/404.vue"),
  },
  {
    path: "/home",
    name: "销售模块",
    iconClass: "fa fa-users",
    redirect: "/sell/WorkBench",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/sell/WorkBench",
        name: "工作台",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/WorkBench"),
      },
      {
        path: "/sell/MouthTask",
        name: "本月任务",
        iconClass: "fa fa-list-ul",
        component: () => import("@/components/sell/MouthTask"),
      },
      {
        path: "/customer/CustomerList",
        name: "客户管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/customer/CustomerList"),
      },
      {
        path: "/customer/CustomerTransfer",
        name: "客户转移",
        iconClass: "fa fa-list",
        component: () => import("@/components/customer/CustomerTransfer"),
      },
      {
        path: "/order/AddOrder",
        name: "新建订单",
        iconClass: "fa fa-list",
        component: () => import("@/components/order/AddOrder"),
      },
      {
        path: "/order/OrderList",
        name: "订单管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/order/OrderList"),
      },
      {
        path: "/sell/SellList",
        name: "审核管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/SellList"),
      },

      {
        path: "/sell/OrderStatistics",
        name: "销售单统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/OrderStatistics"),
      },
      {
        path: "/sell/Drafts",
        name: "草稿箱",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/Drafts"),
      },
      {
        path: "/sell/Business",
        name: "销售任务",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/Business"),
      },
      {
        path: "/sell/AddVisitTask",
        name: "拜访任务",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/AddVisitTask"),
      },
      {
        path: "/sell/VisitList",
        name: "拜访列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/VisitList"),
      },
      {
        path: "/sell/BusinessList",
        name: "销售列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/BusinessList"),
      },
      {
        path: "/sell/ErrorOerders",
        name: "退货管理",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/ErrorOerders"),
      },
      {
        path: "/sell/PersonnelAssocia",
        name: "人员关联",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/PersonnelAssocia"),
      },
      {
        path: "/sell/SellProductionList",
        name: "商品列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/SellProductionList"),
      },
      {
        path: "/sell/ProductionListHalling",
        name: "商品展厅",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/ProductionListHalling"),
      },
      {
        path: "/sell/SellRanking",
        name: "销量排名",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/SellRanking"),
      },
      {
        path: "/sell/CustomerRanking",
        name: "客户排名",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/CustomerRanking"),
      },
      {
        path: "/sell/ExceedTimeLimit",
        name: "销售单超期",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/ExceedTimeLimit"),
      },

      {
        path: "/sell/SalesRank",
        name: "销量员排名",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/SalesRank"),
      },
      {
        path: "/sell/OrderSaleNeed",
        name: "销售需求",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/OrderSaleNeed"),
      },
      {
        path: "/sell/LimitList",
        name: "额度列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/LimitList"),
      },
      {
        path: "/sell/UnDeal",
        name: "未成交客户列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/UnDeal"),
      },
    ],
  },
  {
    path: "/purchase",
    name: "采购管理",
    iconClass: "fa fa-users",
    redirect: "/purchase/PurchaseList",
    component: () => import("@/components/Home"),
    children: [
      // {
      //   path: "/purchase/purchaseworkbench",
      //   name: "采购工作台",
      //   iconClass: "fa fa-list",
      //   component: () => import("@/components/purchase/PurchaseWorkbench"),
      // },

      {
        path: "/purchase/PurchaseList",
        name: "供应商管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/purchase/PurchaseList"),
      },
      {
        path: "/sell/PurchaseCheck",
        name: "供应商审核",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/PurchaseCheck"),
      },
      {
        path: "/purchase/RewMaterList",
        name: "采购单列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/RewMaterList"),
      },
      {
        path: "/sell/RewMaterCheck",
        name: "采购单审核",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/RewMaterCheck"),
      },
      {
        path: "/purchase/ProductionSearch",
        name: "商品查询",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/ProductionSearch"),
      },
      {
        path: "/purchase/ProductionMoneyRanking",
        name: "供应商排序",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/ProductionMoneyRanking"),
      },
      {
        path: "/purchase/ProductionPurchaseList",
        name: "供应商采购统计",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/ProductionPurchaseList"),
      },

      {
        path: "/purchase/OrderSaleNeed",
        name: "采购需求",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/OrderSaleNeed"),
      },
      {
        path: "/purchase/ReconciliationList",
        name: "采购对账",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/ReconciliationList"),
      },
      {
        path: "/purchase/ReconciliationCheck",
        name: "采购对账审核",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/purchase/ReconciliationCheck"),
      },
    ],
  },
  {
    path: "/production",
    name: "生产管理",
    iconClass: "fa fa-users",
    redirect: "/production/ProductionWorkBench",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/production/ProductionWorkBench",
        name: "工作台",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/ProductionWorkBench"),
      },

      {
        path: "/production/ProductionOrderList",
        name: "生产单管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/ProductionOrderList"),
      },

      {
        path: "/production/QualityTesting",
        name: "生产单质检",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/QualityTesting"),
      },

      {
        path: "/purchase/RawMaterial",
        name: "原材料管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/purchase/RawMaterial"),
      },
      {
        path: "/production/RawMaterialList",
        name: "原材料列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/RawMaterialList"),
      },
      {
        path: "/purchase/RawMaterialStore",
        name: "退出仓列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/purchase/RawMaterialStore"),
      },
      {
        path: "/production/RewMateri",
        name: "采购列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/RewMateri"),
      },
      {
        path: "/production/StoreProduction",
        name: "子仓库管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/StoreProduction"),
      },

      {
        path: "/production/GetRewMateriList",
        name: "原材料取料",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/GetRewMateriList"),
      },
      {
        path: "/production/RewMateriStatysitics",
        name: "原材料统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/RewMateriStatysitics"),
      },
      {
        path: "/production/RewMateriRanking",
        name: "加工费排名",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/RewMateriRanking"),
      },
      {
        path: "/production/OrderSaleNeed",
        name: "生产需求",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/production/OrderSaleNeed"),
      },
      {
        path: "/production/ChengStoreLIst",
        name: "转仓取料列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/production/ChengStoreLIst"),
      },
    ],
  },
  {
    path: "/finance",
    name: "财务管理",
    iconClass: "fa fa-users",
    redirect: "/finance/MoneyList",
    component: () => import("@/components/Home"),
    children: [
      // {
      //   path: "/finance/financeworkbench",
      //   name: "财务工作台",
      //   iconClass: "fa fa-list",
      //   component: () => import("@/components/finance/FinanceWorkBench"),
      // },
      {
        path: "/finance/MoneyList",
        name: "费用列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/MoneyList"),
      },

      {
        path: "/finance/FinanceList",
        name: "成本审核",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/FinanceList"),
      },
      {
        path: "/finance/OrdersAccout",
        name: "销售结算",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/OrdersAccout"),
      },
      {
        path: "/finance/ErrorFinanOrder",
        name: "退货管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/ErrorFinanOrder"),
      },
      {
        path: "/finance/FinnceCheck",
        name: "采购审核",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/FinnceCheck"),
      },
      {
        path: "/finance/Finncetotle",
        name: "采购结算",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/FinnceTotle"),
      },

      {
        path: "/finance/ProcessCost",
        name: "加工费结算",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/ProcessCost"),
      },

      {
        path: "/finance/MoneyStatysitics",
        name: "费用统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/MoneyStatysitics"),
      },
      {
        path: "/finance/ExpenseCheck",
        name: "报销审核",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/ExpenseCheck"),
      },
      {
        path: "/finance/FinaceImporter",
        name: "总计导出",
        iconClass: "fa fa-list",
        component: () => import("@/components/finance/FinaceImporter"),
      },
      {
        path: "/sell/UnbalancedAccount",
        name: "未结算列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/UnbalancedAccount"),
      },
      {
        path: "/sell/ClosingPortion",
        name: "部分结算列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/sell/ClosingPortion"),
      },
      {
        path: "/finance/Reconciliation",
        name: "采购对账单",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/finance/Reconciliation"),
      },
     
    ],
  },
  {
    path: "/storeManagement",
    name: "仓库管理",
    iconClass: "fa fa-users",
    redirect: "/storeManagement/StoreWorkBench",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/storeManagement/StoreWorkBench",
        name: "工作台",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/StoreWorkBench"),
      },
      {
        path: "/storeManagement/CommodityManagement",
        name: "商品管理",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/storeManagement/CommodityManagement"),
      },
      {
        path: "/storeManagement/GetMaterial",
        name: "取料列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/GetMaterial"),
      },
      {
        path: "/storeManagement/StoreOrderList",
        name: "订单管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/StoreOrderList"),
      },
      {
        path: "/storeManagement/WaitOrderList",
        name: "待发货订单",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/WaitOrderList"),
      },
      {
        path: "/storeManagement/PruductionStatysitics",
        name: "商品统计",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/storeManagement/PruductionStatysitics"),
      },
      {
        path: "/storeManagement/StoreRawOrderList",
        name: "采购列表",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/storeManagement/StoreRawOrderList"),
      },
      {
        path: "/storeManagement/ErrorStorOrder",
        name: "退货管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/ErrorStorOrder"),
      },
      {
        path: "/storeManagement/ProductAddOrder",
        name: "生产管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/ProductAddOrder"),
      },
      {
        path: "/storeManagement/SonStoreManagement",
        name: "子仓库管理",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/storeManagement/SonStoreManagement"),
      },
      {
        path: "/storeManagement/SonStoreCheck",
        name: "商品盘点",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/SonStoreCheck"),
      },
      {
        path: "/storeManagement/CheckProductNum",
        name: "盘点商品数量",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/CheckProductNum"),
      },
      {
        path: "/storeManagement/CheckStatysitics",
        name: "盘点误差统计",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/storeManagement/CheckStatysitics"),
      },
      {
        path: "/storeManagement/ColorSet",
        name: "颜色代码设置",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/ColorSet"),
      },
      {
        path: "/storeManagement/CodeType",
        name: "分类编码",
        iconClass: "fa fa-list",
        component: () => import("@/components/storeManagement/CodeType"),
      },
      {
        path: "/storeManagement/ChengStoreLIst",
        name: "转仓取料列表",
        iconClass: "fa fa-list-alt",
        component: () => import("@/components/storeManagement/ChengStoreLIst"),
      },
    ],
  },
  {
    path: "/ecommerce",
    name: "电商管理",
    iconClass: "fa fa-users",
    redirect: "/ecommerce/EconMoney",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/ecommerce/EconMoney",
        name: "物流费用",
        iconClass: "fa fa-list",
        component: () => import("@/components/ecommerce/EconMoney"),
      },
      {
        path: "/ecommerce/UpdataOrder",
        name: "订单上传",
        iconClass: "fa fa-list",
        component: () => import("@/components/ecommerce/UpdataOrder"),
      },
    ],
  },
  {
    path: "/jurisdiction",
    name: "权限管理",
    iconClass: "fa fa-users",
    redirect: "/jurisdiction/CompanyJurisdiction",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/jurisdiction/CompanyJurisdiction",
        name: "公司权限",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/jurisdiction/CompanyJurisdiction"),
      },
      {
        path: "/jurisdiction/PersonJurisdiction",
        name: "个人权限",
        iconClass: "fa fa-list",
        component: () => import("@/components/jurisdiction/PersonJurisdiction"),
      },
      {
        path: "/jurisdiction/JurisdictionEmit",
        name: "权限编辑",
        iconClass: "fa fa-list",
        component: () => import("@/components/jurisdiction/JurisdictionEmit"),
      },
      {
        path: "/jurisdiction/JurisdictionSet",
        name: "权限设置",
        iconClass: "fa fa-list",
        component: () => import("@/components/jurisdiction/JurisdictionSet"),
      },
    ],
  },
  {
    path: "/personnel",
    name: "人员管理",
    iconClass: "fa fa-users",
    redirect: "/personnel/PersonnelList",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/personnel/PersonnelList",
        name: "人员列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/personnel/PersonnelList"),
      },
      {
        path: "/personnel/PersonnelCheck",
        name: "人员审核",
        iconClass: "fa fa-list",
        component: () => import("@/components/personnel/PersonnelCheck"),
      },
      {
        path: "/department/DepartmentList",
        name: "部门管理",
        iconClass: "fa fa-list",
        component: () => import("@/components/department/DepartmentList"),
      },
     
      {
        path: "/personnel/PecordList",
        name: "档案列表",
        iconClass: "fa fa-list",
        component: () => import("@/components/personnel/PecordList"),
      }
      
    ],
  },

  {
    path: "/logisticsmanagement",
    name: "物流管理",
    iconClass: "fa fa-users",
    redirect: "/logisticsmanagement/AddLogistcs",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/logisticsmanagement/AddLogistcs",
        name: "新增车辆",
        iconClass: "fa fa-list",
        component: () => import("@/components/logisticsmanagement/AddLogistcs"),
      },
      {
        path: "/logisticsmanagement/BusType",
        name: "车辆类型",
        iconClass: "fa fa-list",
        component: () => import("@/components/logisticsmanagement/BusType"),
      },
      {
        path: "/logisticsmanagement/BusDelivery",
        name: "车辆派送",
        iconClass: "fa fa-list",
        component: () => import("@/components/logisticsmanagement/BusDelivery"),
      },
    ],
  },
  {
    path: "/statysitics",
    name: "统计管理",
    iconClass: "fa fa-users",
    redirect: "/storeManagement/PruductionStatysitics",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/storeManagement/PruductionStatysitics",
        name: "商品统计",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/storeManagement/PruductionStatysitics"),
      },
      {
        path: "/sell/OrderStatistics",
        name: "销售单统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/sell/OrderStatistics"),
      },
      {
        path: "/stytemManagement/Processing",
        name: "加工费统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/stytemManagement/Processing"),
      },

      {
        path: "/production/RewMateriStatysitics",
        name: "原材料统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/RewMateriStatysitics"),
      },
      {
        path: "/production/AssemblyStatysitics",
        name: "流水统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/AssemblyStatysitics"),
      },
      {
        path: "/production/AllOrderStatysitics",
        name: "三单统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/production/AllOrderStatysitics"),
      },
      {
        path: "/statysitics/OrigaiAll",
        name: "原材料收发汇总",
        iconClass: "fa fa-list",
        component: () => import("@/components/statysitics/OrigaiAll"),
      },
      {
        path: "/statysitics/PruductAll",
        name: "商品收发汇总",
        iconClass: "fa fa-list",
        component: () => import("@/components/statysitics/PruductAll"),
      },
    ],
  },
  {
    path: "/stytemManagement",
    name: "系统管理",
    iconClass: "fa fa-users",
    redirect: "/stytemManagement/EarlyWarning",
    component: () => import("@/components/Home"),
    children: [
      {
        path: "/stytemManagement/EarlyWarning",
        name: "个人预警",
        iconClass: "fa fa-list",
        component: () => import("@/components/stytemManagement/EarlyWarning"),
      },
      {
        path: "/stytemManagement/AllEarlyWarning",
        name: "全部预警",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/stytemManagement/AllEarlyWarning"),
      },
      {
        path: "/stytemManagement/PushPerson",
        name: "推送设置",
        iconClass: "fa fa-list",
        component: () => import("@/components/stytemManagement/PushPerson"),
      },
      {
        path: "/stytemManagement/Statistics",
        name: "订单统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/stytemManagement/Statistics"),
      },
      {
        path: "/stytemManagement/Processing",
        name: "加工费统计",
        iconClass: "fa fa-list",
        component: () => import("@/components/stytemManagement/Processing"),
      },
      {
        path: "/stytemManagement/WebsiteImg",
        name: "官网首页图",
        iconClass: "fa fa-list",
        component: () => import("@/components/stytemManagement/WebsiteImg"),
      },
      {
        path: "/stytemManagement/ExpenseCheckliist",
        name: "报销列表",
        iconClass: "fa fa-list",
        component: () =>
          import("@/components/stytemManagement/ExpenseCheckliist"),
      },
    ],
  },
];

// let routes = JSON.parse(window.localStorage.getItem("perjuri"));
//   // 获取路由信息方法
let getRoutes = function () {
  // 自动生成路由
  //1本地权限数据路由
  createRoute(JSON.parse(window.localStorage.getItem("perjuri")), routes);
  att.push(routes[2]);

  //2方法路由
  // let perjuri = [];
  // if (JSON.parse(window.localStorage.getItem("perjuri"))) {
  //   perjuri = fnAddDynamicMenuRoutes(
  //     JSON.parse(window.localStorage.getItem("perjuri"))
  //   );
  //   att = constantRoutes.concat(perjuri);
  // } else {
  //   att = constantRoutes;
  // }

  console.log("att", att);
  return att;
};
export function _imports(file) {
  return () => import("@/components/Home");
}

import Home from "@/components/Home";
//   // 自动生成路由
function _import(str) {
  // views文件夹下的Home组件，传入的格式为 'Home'
  return function (resolve) {
    require([`@/components${str}.vue`], resolve);
  };
}

export function createRoute(arr, list) {
  console.log("本地路由数据", arr);
  // this.$store.state.updateData

  if (arr != null) {
    arr.forEach((item) => {
      var seft = {
        // 循环遍历一级菜单
        path: item.url,
        name: item.name,
        component: Home,
        // meta: { title: item.meta.title, icon: item.meta.icon },
        children: [],
        iconUrl: item.iconUrl,
      };
      if (item.permissionList.length != 0) {
        item.permissionList.forEach((items) => {
          // "@/components/stytemManagement/Statistics"),
          seft.children.push({
            path: items.url,
            name: items.name,
            iconUrl: items.iconUrl,
            component: (resolve) =>
              require([`@/components${items.url}`], resolve), // 使用模板，否则找不到路由地址
            // meta: { title: items.meta.title, icon: items.meta.icon }
          });
        });
      }

      constantRoutes.push(seft);
    });
    console.log("本地路由数据22", constantRoutes);
    // att = constantRoutes;//新  刷新未起效  进404  进首页报错
  }

  att[0] = list[0];
  att[1] = list[1];
  att[2] = list[2];
  let map0 = new Map(); //旧
  let map1 = new Map();
  let map2 = new Map();
  let list11 = [];
  if (arr != null) {
    for (let z = 0; z < arr.length; z++) {
      if (arr[z].permissionList != "undefined") {
        if (arr[z].permissionList.length != null) {
          for (let y = 0; y < arr[z].permissionList.length; y++) {
            map1.set(arr[z].permissionList[y].name + arr[z].name, arr[z].name);

            if (arr[z].permissionList[y].permissionList != null) {
              for (
                let q = 0;
                q < arr[z].permissionList[y].permissionList.length;
                q++
              ) {
                map2.set(
                  arr[z].permissionList[y].permissionList[q].id,
                  arr[z].permissionList[y].name
                );
                list11.push(arr[z].permissionList[y].permissionList[q].id);
              }
            }
          }
        }
      }
      map0.set(arr[z].name, arr[z].name);
      // map0.set(arr[z].iconUrl, arr[z].iconUrl);
    }

    let aazz = JSON.stringify(map2);
    window.localStorage.setItem("soconds", JSON.stringify(list11));
    for (let w = 0; w < list.length; w++) {
      if (map0.get(list[w].name) != null) {
        let lt = [];
        if (list[w].children != null) {
          lt.length = 0;
          lt = [];

          for (let e = 0; e < list[w].children.length; e++) {
            if (
              map1.get(list[w].children[e].name + list[w].name) != null &&
              map1.get(list[w].children[e].name + list[w].name) == list[w].name
            ) {
              lt[lt.length] = list[w].children[e];
            }
          }
        }

        list[w].children = lt;
        // list[w].iconUrl= lt;
        att[att.length] = list[w];

        // att.push[list[w]];
      }
    }
    let mylirtr = JSON.parse(window.localStorage.getItem("perjuri"));
    // let mylirtr = store.state.personjurisdiction;
    att.forEach((item) => {
      mylirtr.forEach((items) => {
        if (item.name == items.name) {
          item["iconUrl"] = items.iconUrl;
          // if (item.children.length != 0) {
          //   item.children.forEach((itemss) => {
          //     itemss["iconUrl"] = items.iconUrl;
          //   });
          // }
        }
      });
    });
  }
}

export default getRoutes();
