/**
 * 节流
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
let timer, flag;
export function throttle(func, wait = 300, immediate = true) {
  if (immediate) {
    if (!flag) {
      flag = true;
      // 如果是立即执行，则在wait毫秒内开始时执行
      typeof func === "function" && func();
      timer = setTimeout(() => {
        flag = false;
      }, wait);
    }
  } else {
    if (!flag) {
      flag = true;
      // 如果是非立即执行，则在wait毫秒内的结束处执行
      timer = setTimeout(() => {
        flag = false;
        typeof func === "function" && func();
      }, wait);
    }
  }
}
export function privoice() {
  return [
    { name: "北京市" },
    { name: "天津市" },
    { name: "上海市" },
    { name: "重庆市" },
    { name: "河北省" },
    { name: "山西省" },
    { name: "辽宁省" },
    { name: "吉林省" },
    { name: "黑龙江省" },
    { name: "江苏省" },
    { name: "浙江省" },
    { name: "安徽省" },
    { name: "福建省" },
    { name: "江西省" },
    { name: "山东省" },
    { name: "河南省" },
    { name: "湖北省" },
    { name: "湖南省" },
    { name: "广东省" },
    { name: "海南省" },
    { name: "四川省" },
    { name: "贵州省" },
    { name: "云南省" },
    { name: "陕西省" },
    { name: "甘肃省" },
    { name: "青海省" },
    { name: "台湾省" },
    { name: "内蒙古自治区" },
	{ name: "广西壮族自治区" },
	{ name: "西藏自治区" },
	{ name: "宁夏回族自治区" },
	{ name: "新疆维吾尔自治区" },
	{ name: "香港特别行政区" },
	{ name: "澳门特别行政区" },

  ];
}
