<template>
  <div class="menu">
    <div class="conpany">
      <img src="../../assets/images/logo/logo.png" alt="" />
    </div>
    <el-aside width="200px">
      <!-- router -->
      <el-menu
      router 
        :default-active="currentIndex"
        class="el-menu-vertical-demo"
        background-color="#fff"
        text-color="#595961"
        @select="openmemu"
        active-text-color="#4587F7"
      >
        <template v-for="(item, index) in menus">
          <!-- <el-submenu :index="index + ''" :key="index" v-if="!item.hidden"> -->
          <el-submenu :index="item.path" :key="index" v-if="!item.hidden">
            <template slot="title">
              <div style="display: flex; align-items: center">
                <!-- <image :src="item.iconUrl" mode=""></image> -->
                <img :src="item.iconUrl" alt="" />
                <!-- <img v-if="item.iconUrl=='../../static/img/menu/poducetion.png'" src="../../static/img/menu/poducetion.png" alt=""/> -->
                <!-- <i :class="item.iconClass"></i> -->
                <span style="margin-left: 10px">{{ item.name }}</span>
              </div>
            </template>
            <el-menu-item-group
              v-for="(child, index) in item.children"
              :key="index"
            >
              <el-menu-item :index="child.path">
               
                <i :class="child.iconClass"></i>{{ child.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [],
      // currentIndex: window.localStorage.getItem("nowrouter"),
      currentIndex: 0,
    };
  },
  watch: {
    $route(route) {
      window.localStorage.setItem("nowrouter", route.path);
      let index = route.path.indexOf("/detail");
      let indexC = route.path.indexOf("/compare");
      if (index !== -1) {
        this.$data.currentIndex = route.path.slice(0, index);
      } else if (indexC !== -1) {
        this.$data.currentIndex = route.path.slice(0, indexC);
      } else {
        this.$data.currentIndex = route.path;
      }
    },
  },
  mounted() {},
  created() {
    this.menus = [...this.$router.options.routes];
    this.currentIndex = this.$route.path;
  },
  methods: {
    openmemu(index, indexPath) {
      console.log("点击了", index, indexPath);
      // const { href } = this.$router.resolve({
      //   path: index,
      // });
      // window.open(href,'_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  .el-aside {
    height: 100%;
    .el-menu {
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        //display: none;
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(144, 147, 153, 0.5);
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
      }
      .fa {
        margin-right: 10px;
      }
      .el-submenu .el-menu-item {
        min-width: 0;
      }
    }
  }
}
.conpany {
  line-height: 60px;
  height: 60px;
  width: 200px;
  background-color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding-top: 16px;
  box-sizing: border-box;
  .conpany-img {
    line-height: 39px;
    height: 39px;
    width: 80px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
}
::v-deep .el-aside::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.el-aside {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.menu .el-aside .el-menu::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}
</style>
