import { Icon } from "element-ui";
import Vue from "vue";
import Router from "vue-router";
import routes from "@/utils/menu";

Vue.use(Router);
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

// console.log("路径", routes);
// const routes = [
//   {
//     path: "/",
//     redirect: "/login",
//     hidden: true,
//     component: () => import("@/components/Login"),
//   },
//   {
//     path: "/login",
//     name: "Login",
//     hidden: true,
//     component: () => import("@/components/Login"),
//   },
//   {
//     path: "*",
//     name: "NotFound",
//     hidden: true,
//     component: () => import("@/components/404.vue"),
//   },
// ];
const router = new Router({
  // 创建路由的实例对象
  routes,

  mode: "hash",
});

export default router;
