import Vue from "vue";
import Vuex from "vuex";
import { nanoid } from "nanoid";
import user from "./modules/user";
import socket from "./modules/user";
import Socket from "@/utils/websocket"; //socket 方法类
import createRoute from '@/utils/menu'

Vue.use(Vuex);

// 用来存储数据
const state = {
  ws: null, // websorket实例
  person: [
    { id: nanoid(), personName: "张三", age: 18 },
    { id: nanoid(), personName: "张4", age: 28 },
    { id: nanoid(), personName: "张5", age: 38 },
  ],
  personjurisdiction:[],
  toteldatas:''
};
// 响应组件中的事件
const actions = {
  //添加人员
  addPerson(content, value) {
  
    const person = {
      id: nanoid(),
      personName: value,
      age: 18, //暂时写死
    };
    content.commit("addPerson", person);
  },
  // 创建实例
  socketInit({ commit, state }) {
   
    commit("contentSocket", { commit });
  },
};
// 操作数据
const mutations = {
  subscribe_socket(state, { data }) {
    //这里的data为socket连接后端返回来的数据
  },
  updateData(state,data){
    state.personjurisdiction = data
    console.log('static vuex',data)

  },
  toteldatass(state,data){
    state.toteldatas = data
    console.log('qqqqqq',state.toteldatas)

  },
  contentSocket(state, { commit }) {
    state.ws = new Socket(commit);
  },
  addPerson(_, value) {
    this.state.person.unshift(value);
  },
  deletePerson(_, id) {
    const newArr = this.state.person.filter((p) => {
      return p.id != id;
    });
    this.state.person = newArr;
  },
};
// 用来将state数据进行加工：类似于computed
const getters = {
  getAllPersonAge(state) {
    var sumAge = 0;
    state.person.forEach((element) => {
      sumAge += element.age;
    });
    return sumAge;
  },
};
// 新建并暴露store
export default new Vuex.Store({
  user,
  state,
  actions,
  mutations,
  getters,
  socket
});
