<template>
  <!-- v-title data-title="华久" -->
  <div id="app">
    <!-- <transition mode="out-in"> -->
      <router-view></router-view>

    <!-- </transition> -->
    <!-- <router-view target="_blank" :to=" topath.path"></router-view> -->
    <!-- <router-link target="_blank" :to="topath.path"></router-link> -->
    <audio id="audios" hidden src="./static/img/voice/news.mp3"></audio>
  </div>
</template>

<script>
// import Waves from '@/common/waves.vue'
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      loop: true,
      isPlay: false,
      muted: true,
      isRouterAlive: true,
      controls: false,
      redatadata: {},
      topath: {},
      // currentTime: 0,
      lockReconnect: false, //是否真正建立连接
      timeout: 60 * 1000, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
    };
  },
  name: "App",
  // components: {Waves},

  watch: {
    $route(to, form) {
      console.log("监听路由 $router", to, form);
      // this.topath = to;
      // this.$nextTick(()=>{
      //   const { href } = this.$router.resolve({
      //   path: to.path,
      // });
      // window.open(href,'_blank');
      // })
      
    },
  },
  created() {
    if (window.localStorage.getItem("refreshid") == 1) {
      window.location.reload();
      window.localStorage.setItem("refreshid", 0);
    }
  },

  mounted() {
    window.localStorage.setItem("uploadtxt0", "");
    window.localStorage.setItem("uploadtxt1", "");
    window.localStorage.setItem("uploadtxt2", "");
    window.localStorage.setItem("uploadtxt3", "");
    window.localStorage.setItem("uploadtxt4", "");
    window.localStorage.setItem("uploadtxt5", "");
    window.localStorage.setItem("uploadtxt6", "");
    window.localStorage.setItem("uploadimg0", "");
    window.localStorage.setItem("uploadimg1", "");
    window.localStorage.setItem("showimages", "");
    window.localStorage.setItem("showimage", "");
    window.localStorage.setItem("storeshowimages", "");
    window.localStorage.setItem("aloneshowimages", "");
    //需要
    window.addEventListener("click", this.musicAutoPlay);
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));

    if (
      window.localStorage.getItem("token") != "" ||
      window.localStorage.getItem("token") != null
    ) {
      this.iniWebSocket();
    } else {
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    musicAutoPlay() {
      this.isPlay = true;
    },
    playSound() {
      let sound = new Audio("./static/img/voice/news.mp3");
      sound.play();
    },
    voids() {
      const audio = document.getElementById("audios");
      audio.play();
    },
    beforeunloadHandler(e) {
      // debugger

      //清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      return "关闭提示";
    },
    iniWebSocket() {
      //初始化websocket192.168.0.103   http://192.168.0.103:8090
      const wsuri = "ws://123.60.73.210:19999/websocket";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      // this.websock.onclose = this.websocketclose;//断开连接
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.iniWebSocket();
        that.lockReconnect = false;
      }, 30000);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
      //开启心跳
      console.log("开始心跳");
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息
        if (self.websock.readyState == 1) {
          //如果连接正常
          self.websock.send(
            JSON.stringify({
              type: "HEARTBEAT",
              userId: JSON.parse(window.localStorage.getItem("useronfo")).id,
            })
          );
        } else {
          //否则重连
          self.reconnect();
        }
        self.start();
        // self.serverTimeoutObj = setTimeout(function () {
        //   //超时关闭
        //   self.websock.close();
        // }, self.timeout);
      }, self.timeout);
    },
    sendxt() {
      // let actions = { type: "心跳数据" };
      // this.websocketsend(JSON.stringify(actions));
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      //       TEXT("普通文本消息"),
      // HEARTBEAT("心跳数据"),
      // REGISTER("注册数据")
      if (window.localStorage.getItem("token") != null) {
        let actionsa = {
          type: "REGISTER",
          userId: JSON.parse(window.localStorage.getItem("useronfo")).id,
        };
        let hjkrhtgid = JSON.parse(window.localStorage.getItem("useronfo")).id;
        let actions = {
          type: "HEARTBEAT",
          userId: JSON.parse(window.localStorage.getItem("useronfo")).id,
        };
        this.websocketsend(JSON.stringify(actions));

        this.websocketsend(JSON.stringify(actionsa));
        //开启心跳
        this.start();
      }
    },
    websocketonerror(e) {
      //连接建立失败重连
      this.iniWebSocket();
      this.reconnect();
    },
    websocketonmessage(e) {
      //数据接收
      // let fdhgfh = '10000:{"context":"商品库存不足，请及时补仓","type":"20"}';
      let fdhgdfvvc =
        "用户：【" +
        JSON.parse(window.localStorage.getItem("useronfo")).id +
        "】上线啦！";
      let fdhgdfvvc11 =
        "用户：【" +
        JSON.parse(window.localStorage.getItem("useronfo")).id +
        "】下线啦！";
      if (e.data == fdhgdfvvc) {
      } else if (e.data == fdhgdfvvc11) {
      } else {
        try {
          const redata = JSON.parse(e.data.slice(e.data.indexOf("{", 0)));
          // 10原材料   20商品
          if (redata.type == 10) {
            var websocketonmessagetype = "原材料";
          } else {
            var websocketonmessagetype = "商品";
          }
          // this.$refs.audio.currentTime = 0; //从头开始播放提示音
          // this.$refs.audio.play(); //播放
          // this.$refs["audio"].play();
          // this.voids();
          this.redatadata = redata;
          var nullobject = Object.keys(redata);
          if (nullobject.length != 0) {
            window.addEventListener("click", this.voids());
            this.voids();
          }
          this.$notify({
            title: redata.title,
            message: redata.content,
            position: "bottom-right",
            duration: 14000,
            offset: 200,
          });
        } catch (e) {}
      }
      this.reset();
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      //重连
      this.reconnect();
    },
  },
};
</script>

<style lang="scss">
@import url("./assets/css/reset.css");

@import url("./assets/css/common.css");
@import url("./assets/css/worksben.css");
@import url("./assets/css/searchInput.css");
@import url("./assets/css/showList.css");
@import url("./assets/css/showMoudel.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
}
html {
  height: 100%;
}
</style>
